.form_container{
    width: 30%;
}

.list-group a:hover{
    background-color: blue;
    color: white;
}

.sidebar{
    width: 280px;
}

@media screen and (max-width: 1000px) {
    .form_container{
        width: 45%;
    }
}

@media screen and (max-width: 700px) {
    .form_container {
        width: 65%;
    }
    
}
